/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins");

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
  --xdock-scan-success-color: #baf1c6;
  --xdock-scan-warning-color: #fff3cd;
}

// Media query for dark mode
@media (prefers-color-scheme: dark) {
  :root {
    --xdock-scan-success-color: #207d45;
    --xdock-scan-warning-color: #d7b857;
  }
}

ion-header {
  ion-toolbar {
    ion-title {
      font-size: 17px;
    }
  }
}

.action-sheet-button:not(:last-child) {
  border-bottom: 2px solid var(--ion-color-step-200) !important;
}

.custom-success-light {
  background-color: var(--xdock-scan-success-color) !important;
}

.bg-warning-light {
  background-color: var(--xdock-scan-warning-color) !important;
  /* Custom background color */
}

.ionic-selectable-modal {
  ion-list {
    background: transparent;
    padding: 16px;

    ion-item-group {
      border-radius: 10px;
      background: var(--ion-item-background);
    }

    ion-item {
      border-radius: 0;
      --background: transparent;
      --border-color: transparent;
      --background-hover: transparent;
      border-bottom: 1px solid var(--ion-item-border-color);
    }

    ion-item:last-child {
      border-bottom: 0;
    }

    ion-checkbox {
      --border-color: #c6c6c6;
      --border-color-checked: var(--ion-color-primary);
      --border-radius: 5px;
      --size: 23px;
    }
  }
}

ionic-selectable .ionic-selectable-icon,
ion-select::part(icon) {
  top: 0px !important;
}

.sc-ion-searchbar-md-h {
  --box-shadow: none;
  --border-radius: 10px;
}

.first-heading {
  background: var(--ion-color-primary);
  color: #fff;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
}

.scan-custom-alert {
  .alert-button-group {
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    // border: 2px solid black;
  }

  button.alert-button:nth-child(1) {
    flex-basis: 100%;

    // Default button styles
    margin: 0px !important;
    font-size: 18px;

    // Button active styles
    &:active {
      background: var(--ion-color-danger);
      color: #fff;
    }
  }

  button.alert-button:nth-child(2) {
    flex-basis: 100%;

    // Default button styles
    margin: 0px !important;
    font-size: 18px;

    // Button active styles
    &:active {
      background: var(--ion-color-primary);
      color: #fff;
    }
  }

  button.alert-button:nth-child(1) span {
    justify-content: center !important;
  }

  button.alert-button:nth-child(2) span {
    justify-content: center !important;
  }
}

.alert-wrapper {
  border-radius: 10px !important;
  border: 2px solid #696666;
}

ion-alert {
  .alert-button-group {
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    // border: 2px solid black;
  }

  button.alert-button:nth-child(1) {
    flex-basis: 100%;

    // Default button styles
    margin: 0px !important;
    font-size: 18px;

    // Button active styles
    &:active {
      background: var(--ion-color-primary);
      color: #fff;
    }
  }

  button.alert-button:nth-child(1) span {
    justify-content: center !important;
  }
}

.manual-barcode-alert {
  .alert-radio-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .alert-radio-inner {
    width: 20px !important;
    height: 20px !important;
  }

  .alert-radio-label {
    font-size: 20px !important;
  }

  .alert-wrapper {
    min-width: 70% !important;
  }

  button:not(:last-child) {
    border-bottom: 1px solid var(--ion-color-step-200) !important;
  }

  .alert-radio-group {
    border-bottom: 1px dashed var(--ion-color-step-200) !important;
    border-top: 1px dashed var(--ion-color-step-200) !important;
  }
}

.border-radius {
  border-radius: 10px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.custom-skeleton-text {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  align-items: center;
}

.page-info-section {
  background-color: #cce5ff !important;
  color: #004085 !important;
  padding: 16px !important;
  text-align: center !important;
}

.purchase-order-info-section {
  background-color: #d4edda;
  /* Light success color */
  color: #155724;
  /* Dark green text for success */
  padding: 10px;
  font-size: 15px;
  text-align: center;
  // border-radius: 8px; /* Optional: For rounded corners */
  // margin: 8px 0; /* Space between sections */
  display: block;
  /* Ensure each section is on a new line */
}

.custom-input-border {
  border: 2px solid #dedede;
  border-radius: 10px;
}

ion-icon {
  cursor: pointer;
}

.ionic-selectable-modal ion-list {
  padding: 0px;
}